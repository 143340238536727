@import "variables";
@import "framework/framework";

@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-black;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	line-height: 1;

	@media screen and (max-height: 900px){
		font-size: 15px;
	}

	@media screen and (max-height: 800px){
		font-size: 12px;
	}
	@media screen and (max-width: 609px){
		font-size: 16px;
	}
}

body {
	background-image: url('../img/bg.png');
	background-size: cover;
	color: $color-white;
	min-height: 100vh;
	height: auto;
	overflow-x: hidden;
	overflow-y: scroll;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-primary;
	}
}

#main{
	width: rem(1250);
	padding: rem(60) rem(5);
	@media screen and (max-width: 610px){
		padding: rem(30) 0;
	}
	margin: 0 auto;
	max-width: 100%;
}

.book-controls{
	padding: rem(50) 0 0;
	text-align: center;

	button{
		width: rem(200);
		color: $color-white;
		padding: rem(10) 0;
		border: 2px solid $color-white !important;
		background: transparent;
		text-transform: uppercase;
		letter-spacing: .1em;
		border: none;
		font-weight: bold;
		&+button{
			margin-left: rem(15);
		}
		@media screen and (max-width: 440px){
			width: rem(150);
			letter-spacing: .05em;
			font-size: rem(14);
			&+button{
				margin-left: rem(0);
			}
		}
		@include hover-focus{
			color: $color-black;
			background: $color-primary !important;
			border-color: $color-primary !important;
		}
		
	}
}

.wrapper{
	padding: rem(20) rem(20);
	margin: 0 auto;
	width: rem(1200);
	max-width: 100%;
	// display: inline-block;
	background-image: url('../img/book.png');
	background-size: 100% auto;
	background-position: center right;
	background-repeat: no-repeat;
	filter: drop-shadow(0 rem(20) rem(30) rgba(0, 0, 0, .8));
	
	position: relative;
	transition: left .3s;
	left: 0;
	@media screen and (min-width: 609px){
		&.closed{
			left: calc(-25%);
			background-image: url('../img/book-closed.png');
			background-size: 50% auto;
		}
	}

	@media screen and (max-width: 1070px){
		padding: rem(10);
	}
	@media screen and (max-width: 620px){
		padding: rem(10) rem(10) rem(10) 0;
		background-image: url('../img/book-closed.png');
	}

	
}

#book{
	width: 100%;
	.page .video{
		position: absolute;
		width: percent(535, 688);
	}
	.page{
		// width: 688;
		// height: 1004;

		@media screen and (min-width: 610px){
			&:nth-child(even)::after{
				content: '';
				pointer-events: none;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 10%;
				background-image: linear-gradient(to left, rgba(0,0,0,.4), rgba(0,0,0,0));
			}
		}

		@media screen and (max-width: 609px){
			&::after{
				content: '';
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0; 
				width: 10%;
				background-image: linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,0));
			}
		}

		&.black{
			background-color: $color-white;
			color: $color-black;
			background-image: url('../img/page-bg.png');
			background-size: cover;
			background-position: top left;
			padding: rem(20);
			button{
				position: absolute;
				top: 75%;  
				left: 50%;
				transform: translate(-50%, -50%);
				width: rem(280);
				color: $color-black;
				padding: rem(10) 0;
				border: 2px solid $color-black !important;
				background: transparent;
				text-transform: uppercase;
				letter-spacing: .1em;
				border: none;
				font-weight: bold;
				@include hover-focus{
					color: $color-black;
					background: $color-primary !important;
					border-color: $color-primary !important;
				}
			}	
			
			iframe{
				position: absolute;
				bottom: rem(10);
				left: 50%; 
				top: 50%;
				width: 90%;
				transform: translate(-50%, -50%);
			}
		}

		&:nth-child(3){
			.video{
				top: percent(290, 1004);
				left: percent(84, 688);
			}
		}
		&:nth-child(4){
			.video{
				top: percent(300, 1004);
				left: percent(70, 688);
			}
		}
		&:nth-child(6){
			.video{
				top: percent(330, 1004);
				left: percent(74, 688);
			}
		}

		&:nth-child(8){
			.video{
				top: percent(645, 1004);
				left: percent(74, 688);
			}
		}

		&:nth-child(9){
			.video{
				width: percent(530, 688);
				top: percent(365, 1004);
				left: percent(87, 688);
			}
		}
		&:nth-child(12){
			.video{
				width: 100%;
				bottom: 0;
				left: 0;
			}
		}
		&:nth-child(13){
			.video{
				width: percent(530, 688);
				top: percent(116, 1004);
				left: percent(87, 688);
			}
		}
		&:nth-child(14){
			.video{
				width: percent(530, 688);
				top: percent(650, 1004);
				left: percent(74, 688);
			}
		}
		&:nth-child(15){
			.video{
				width: percent(530, 688);
				top: percent(265, 1004);
				left: percent(87, 688);
			}
		}

		&:nth-child(16){
			.video{
				width: percent(530, 688);
				top: percent(402, 1004);
				left: percent(75, 688);
			}
		}

		&:nth-child(17){
			.video{
				top: percent(68, 1004);
				left: percent(85, 688);
			}
		}

		&:nth-child(18){
			.video{
				width: percent(435, 688);
				top: percent(330, 1004);
				left: percent(42, 688);
			}
		}

		&:nth-child(19){
			.video{
				width: percent(435, 688);
				top: percent(705, 1004);
				left: percent(212, 688);
			}
		}

		&:nth-child(21){
			.video{
				top: percent(500, 1004);
				left: percent(83, 688);
			}
		}

		&:nth-child(22){
			.video{
				top: percent(128, 1004);
				left: percent(72, 688);
			}
		}

		&:nth-child(23){
			.video{
				top: percent(618, 1004);
				left: percent(78, 688);
			}
		}

		&:nth-child(24){
			.video{
				top: percent(232, 1004);
				left: percent(72, 688);
			}
		}
	}
}

#footer{
	font-size: rem(11);
	letter-spacing: .2em;
	line-height: 1.5;
	padding-bottom: rem(20);
	text-transform: uppercase;

	.container{
		width: 100%;
		max-width: rem(1100);
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center; 
		
	
		img{
			padding: rem(10);
		}
		.copyright{
			flex: 1;
			padding: 0 rem(10); 
		}
		@media screen and (max-width: 609px){
			flex-wrap: wrap;
			.copyright{
				flex-basis: 100%;
				font-size: rem(8);
			}
		}
	}
}

.popup{
	position: relative;
	display: block; 
	width: rem(800);
	max-width: 100%;
	background-color: white;
	padding: rem(10);
	margin: rem(30) auto;
	overflow-y: visible;
	&.mpf-hide{
		opacity: 1;
	}

	#wyng-experience{
		overflow-y: visible;
		overflow-x: hidden;
		padding: rem(30);
		position: static !important;
	}
}